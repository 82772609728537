::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.modal{
    position: fixed;
    top: 2%;
    max-height: 650px;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 700px;
    height: auto;
    background: #ffffff;

    margin: auto;
    padding: 0 2rem;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal__header{
    display: block;
    width: 100%;
    justify-content: left;
    margin-right: 5px;
}

.modal__title{
    display: flex;
    font-size: 2.75rem;
    font-weight: 800;
    text-align: left;
    justify-content: left;
    color: var(--secondary-color);
    text-transform: capitalize;
    margin-top: 20px;

    @media screen and (max-width: 1000px) {
        font-size: 1.75rem;
        font-weight: 800;
      }
}
.modal__short-desc{
    margin-top: 5px;
    color: var(--black);
}

.modal__subheader{
    font-size: 1.75rem;
    font-weight: 800;
    text-align: left;
    justify-content: left;
    text-transform: capitalize;
    margin-top: 10px;
    color: var(--secondary-color);

    @media screen and (max-width: 1000px) {
        font-size: 1.25rem;
        font-weight: 800;
      }
}

.modal__header-icons{
    display: flex;
    justify-content: left;
}

.grid_wrapper {
  display: grid;
  justify-content: left;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: 150px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 100px;
  }

  @media screen and (max-width: 400px) {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 100px;
  }

}


.modal__icon{
    padding-top: 8px;
    padding-left: 10px;
    color: #000000;
    cursor: pointer;
}

.modal__content{
    width: 100%;
    display: block;
}

.modal__media{
    margin-top: 10px;
    width: 100%;
    img{
        border-radius: 10px;
    }
}

.modal__desc{
    margin-top: 5px;
    width: 100%;
}

.modal__img{
    width: 100%;
    height: auto;

}

.modal__tech{
    width: 100%;
    display: block;
    justify-content: left;
}

.modal__tech_icons{
    display: flex;
}



.modal__tech_size{
    flex-direction: column;
    text-align: center;
  
    margin: 1rem;
  
    transition: all 0.3s ease-in-out;
  
    div {
      width: 90px;
      height: 90px;
      border-radius: 50%;
      background-color: #fef4f5;

      /*background-color: #fef4f5;*/

      @media screen and (max-width: 1000px) {
        width: 40px;
        height: 40px;
      }


      @media screen and (max-width: 400px) {
        width: 40px;
        height: 40px;
      }
  
  
      img {
        width: 50%;
        height: 50%;
      }
  
      &:hover {
        box-shadow: 0 0 25px #fef4f5;
      }
  
      @media screen and (min-width: 2000px) {
        width: 150px;
        height: 150px;
      }
  

    }
  
    p {
      font-weight: 500;
      margin-top: 0.5rem;
    }
  
    @media screen and (min-width: 2000px) {
      margin: 1rem 2rem;
  
      p {
        margin-top: 1rem;
      }
    }
  }

  .modal__video{
    height: 360px;
    border: 0;
    border-radius: 5px;
  }


@media only screen and (max-width: 1000px) {
    .modal{
        width: 400px;
    }
    
    .modal__video{
      height: 190px;
    }

    .modal__content{
        display: block;
    }
    .modal__media{
        width: 100%;
    }
    
    .modal__desc{
        padding-left: 0;
        width: 100%;
        padding-bottom: 10px;
    }
}

@media only screen and (max-width: 400px) {
  .modal{
      width: 300px;
      height: 620px;
  }

  .modal__video{
    height: 130px;
  }
  
  .modal__content{
      display: block;
  }
  .modal__media{
      width: 100%;
  }
  
  .modal__desc{
      padding-left: 0;
      width: 100%;
      padding-bottom: 10px;
  }
}