.app__about {
    flex: 1;
    width: 100%;
    flex-direction: column;
  }

  .wrap{
    flex: 1;
    width: 100%;
    flex-direction: column;
    padding: 4rem 2rem;
    padding-bottom:0px;

    @media screen and (max-width: 450px) {
      padding: 4rem 1rem 2rem;
    }
  }

  .app__flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .container {
    width: 100%;

    display: flex;
    flex-direction: row;
  }
  
  
  .app__profiles {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 2rem;
  }
  
  .app__profile-item {
    width: 190px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin: 2rem;
  
    img {
      width: 100%;
      height: 170px;
      border-radius: 15px;
      object-fit: cover;
    }
  
    @media screen and (min-width: 2000px) {
      width: 370px;
      margin: 2rem 4rem;
  
      img {
        height: 320px;
      }
    }
  }
  