body {
    position: relative;
    height: auto;
    min-height: 100% !important;
  }
  
.backdrop {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #000000e1;
    display: flex;
    justify-content: center;
    z-index: 3;
}